import React from "react";
import UsersList from "./UsersList";

const UsersContainer = () => {
  return (
    <div>
      <UsersList />
    </div>
  );
};

export default UsersContainer;
