import { toast } from "react-toastify";
import axiosClient from "../../axios-client";

export const fetchAllUsers = async () => {
  try {
    const response = await axiosClient.get("setting/getAllProfile");
    if (response.data.statusCode === 200) {
      return response.data.Users;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching all users");
  }
};
// api to get all categories
export const fetchCategories = async () => {
  try {
    const response = await axiosClient.get("category/getAllCategory");
    if (response.data.statusCode === 200) {
      return response.data.categoryList;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching categories");
  }
};
// api to delete categories
export const deleteCategory = async (CatId) => {
  try {
    const response = await axiosClient.delete(
      `category/deleteCategory/${CatId}`
    );

    if (response.data.statusCode === 200) {
      return response.data.categoryList;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching categories");
  }
};
// api to delete subCategories
export const deleteSubCat = async (CatId, req) => {
  try {
    const response = await axiosClient.post(
      `subcategory/deleteSubCategory/${CatId}`,
      req
    );

    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching categories");
  }
};
// api to delete Item
export const deleteItem = async (CatId, req) => {
  try {
    const response = await axiosClient.put(
      `sub-category-items/StatusUpdateItem/${CatId}`,
      req
    );

    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching categories");
  }
};
//Api to Update Category
export const UpdateCategory = async (request, CatId) => {
  try {
    const response = await axiosClient.put(
      `category/updateCategory/${CatId}`,
      request
    );

    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching categories");
  }
};

//Api to Update subCategory
export const UpdateSubCategory = async (request, CatId) => {
  try {
    const response = await axiosClient.post(
      `subcategory/updateSubCategory/${CatId}`,
      request
    );

    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error update sub Category");
  }
};
//Api to Update Item
export const UpdateItem = async (request, CatId) => {
  try {
    const response = await axiosClient.put(
      `sub-category-items/updateItem/${CatId}`,
      request
    );

    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error update sub Category");
  }
};
// api to Add new Category
export const AddNewCat = async (newCatData) => {
  try {
    const response = await axiosClient.post("category/addCategory", newCatData);
    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Adding Category");
  }
};
// api to Add new Sub Category
export const AddNewSubCat = async (newSubCatData) => {
  try {
    const response = await axiosClient.post(
      "subcategory/insertSubCategory",
      newSubCatData
    );
    if (response.data.statusCode === 200) {
      return response.data;
    } else if (response.data.statusCode === 400) {
      toast.error(response.data.message);
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Adding Sub Category");
  }
};
// api to Add new Sub Category
export const AddNewSubCatItem = async (newItemData) => {
  try {
    const response = await axiosClient.post(
      "sub-category-items/addItems",
      newItemData
    );
    if (response.data.statusCode === 200) {
      return response.data;
    } else if (response.data.statusCode === 400) {
      toast.error(response.data.message);
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Adding Sub Category");
  }
};
// api to get subCategories by catehory ID
export const fetchSubCatByCatId = async (CatId) => {
  try {
    const response = await axiosClient.get(
      `subcategory/getSubCategorys/${CatId}`
    );

    if (response.data.statusCode === 200) {
      return response.data.subCategoryList;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching SubCategories");
  }
};
// api to get all subCategories
export const fetchSubCategories = async () => {
  try {
    const response = await axiosClient.get("subcategory/getAllSubCategory");

    if (response.data.statusCode === 200) {
      return response.data.subCategoryList;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching categories");
  }
};
// api to get all subCategory items
export const fetchItems = async () => {
  try {
    const response = await axiosClient.get(
      "sub-category-items/getAllSubcategoryItems"
    );

    if (response.data.statusCode === 200) {
      return response.data.subCategoryItems;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching all subCategory items");
  }
};
// api to get all subCategory items
export const fetchItemsBySubCatID = async (SubCat) => {
  try {
    const response = await axiosClient.get(
      `sub-category-items/getSubcategoryItembySubcat/${SubCat}`
    );

    if (response.data.statusCode === 200) {
      return response.data.subCategoryItems;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching all subCategory items");
  }
};
//api to get userDetails
export const fetchUserDetails = async (userId) => {
  try {
    // const response = await axiosClient.get(`setting/getProfileDt/${user.id}`);
    const response = await axiosClient.get(`setting/getProfileDt/${userId}`);
    if (response.data.statusCode === 200) {
      return response.data.profile;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching userDetails");
  }
};
// api to update profile setting
export const updateProfile = async (userDetails, userId) => {
  try {
    console.log(userDetails, "userDetails in data");
    const response = await axiosClient.put(
      `setting/updateProfile/${userId}`,
      userDetails
    );
    if (response.data.statusCode === 200) {
      return response.data.profile; // Assuming the API returns the updated profile
    } else if (response.data.statusCode === 404) {
      throw new Error(response.data.msg);
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error updating user profile");
  }
};
// api to get all products
export const fetchAllProducts = async () => {
  try {
    const response = await axiosClient.get("product/getAllProducts");

    if (response.data.statusCode === 200) {
      return response.data.catList;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching allProducts");
  }
};
// api to get Product Categories
export const fetchProductCategories = async () => {
  try {
    const response = await axiosClient.get(
      "product-categories/getAllProductCategories"
    );

    if (response.data.statusCode === 200) {
      return response.data.catList;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching Product categories");
  }
};
// api to Post sponsord ads
export const postSponsordAd = async (sponsordAd) => {
  try {
    const response = await axiosClient.post("ads/AddSponsoredAds", sponsordAd);

    if (response.data.statusCode === 200) {
      return response;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw new Error("Error Posting sponsord");
  }
};
// api to get all ads list by id
export const fetchSponsordAdList = async (req) => {
  try {
    const response = await axiosClient.put(`ads/ListSponsoredAds`, req);

    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching SponsoredAds");
  }
};
// api to Update sponsord ads
export const updateSponsordAdStatus = async (adDetails, adId) => {
  try {
    const response = await axiosClient.put(
      `ads/updateSponsoredAdsStatus/${adId}`,
      adDetails
    );

    if (response.data.statusCode === 200) {
      return response.data; // Assuming the API returns the updated profile
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    console.error("Error Approving Ad:", error.message);
    throw new Error("Error Approving Ad ");
  }
};
// api to Update sponsord ads
export const updateSponsordAd = async (adDetails, id) => {
  try {
    console.log(adDetails, id, "adDetails in data");
    const response = await axiosClient.put(
      `ads/updateSponsoredAds/${id}`,
      adDetails
    );

    if (response.data.statusCode === 200) {
      return response.data; // Assuming the API returns the updated profile
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    console.error("Error updating Ad:", error.message);
    throw new Error("Error updating Ad ");
  }
};
// api to post request/need
export const postRequest = async (requestData) => {
  console.log(requestData, "requestData in data");
  try {
    const response = await axiosClient.post("need/PostNeed", requestData);

    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Posting request");
  }
};
// api to fetch all gurdwara
export const fetchGurdwaras = async () => {
  try {
    const response = await axiosClient.get("need/GurudwaList");
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching gurdwaras");
  }
};
// api to get all my needs/requests by id
export const fetchMyNeedsOrRequests = async (data) => {
  try {
    const response = await axiosClient.put(`need/ListAllNeeds`, data);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching needs/requests");
  }
};
// api to duplicate the request
export const duplicateRequest = async (requestId) => {
  console.log(requestId, "requestId");
  try {
    const response = await axiosClient.post("need/DuplicateNeeds", requestId);

    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Duplicating request");
  }
};
// api to Edit request
export const updateRequest = async (requestId, requestData) => {
  try {
    const response = await axiosClient.put(
      `need/UpdateNeed/${requestId}`,
      requestData
    );

    if (response.data.statusCode === 200) {
      return response.data; // Assuming the API returns the updated profile
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    console.error("Error updating request:", error.message);
    throw new Error("Error updating request ");
  }
};
// api to delete my request
export const deleteMyNeeds = async (data, needId) => {
  try {
    const response = await axiosClient.put(`need/DeleteNeed/${needId}`, data);
    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    console.error("Error deleting my request:", error.message);
    throw new Error("Error deleting my request ");
  }
};
// api to Pause my request
export const statusOfRequest = async (createdBy, needId) => {
  try {
    console.log(createdBy, needId, "createdBy in data");
    const response = await axiosClient.put(
      `need/StatusUpdateNeed/${needId}`,
      createdBy
    );

    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    console.error("Error Pauseing my request:", error.message);
    throw new Error("Error Pauseing my request ");
  }
};
// api to Fetch country codes from REST Countries API
export const fetchCountryCodes = async () => {
  try {
    const response = await fetch("https://restcountries.com/v3.1/all");
    const data = await response.json();

    // Extract relevant data for country codes
    const codes = data.map((country) => ({
      label: country.name.common,
      value: country.callingCodes[0],
    }));
    return codes;
  } catch (error) {
    console.error("Error fetching country codes:", error);
  }
};
// api to fetch LocalCommunity ads
export const fetchLocalCommunityAds = async (payload) => {
  const request = payload ? payload : {};
  try {
    const response = await axiosClient.put(`need/LocalCommunityList`, request);

    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching LocalCommunity ads");
  }
};
// api to fetch LocalCommunity ads by id
export const fetchLCAById = async (id) => {
  try {
    const response = await axiosClient.put(`need/LocalCommunityListById/${id}`);

    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching LocalCommunity ads");
  }
};
// api to fetch Browse wedding vendors
export const fetchBWV = async () => {
  try {
    const response = await axiosClient.get(`services/ListAllServices`);

    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching vendors");
  }
};
// api to post my service
export const postMyService = async (formData) => {
  try {
    const response = await axiosClient.post("services/addService", formData);

    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Posting my service");
  }
};
// api to fetch my services
export const statusUpdateService = async (reqData, serviceId) => {
  try {
    const response = await axiosClient.put(
      `/services/StatusUpdateService/${serviceId}`,
      reqData
    );

    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching Services");
  }
};
// api to Make featured
export const Featured = async (reqData, serviceId) => {
  try {
    const response = await axiosClient.put(
      `services/FeaturedListing/${serviceId}`,
      reqData
    );

    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching Services");
  }
};
// api to fetch my services
export const fetchMyServices = async (reqData) => {
  try {
    const response = await axiosClient.put(
      `services/ListAllServiceById`,
      reqData
    );

    if (response.data.statusCode === 200) {
      console.log(response.data.Data, "response.data.Data");
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching Services");
  }
};
// api to fetch All Services
export const fetchAllServices = async () => {
  try {
    const response = await axiosClient.get(`services/ListAllServices`);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching Services");
  }
};
// api to fetch category options list
export const fetchOptionsList = async (catId) => {
  try {
    const response = await axiosClient.get(
      `category-list/getAllCategoryList/${catId}`
    );

    if (response.data.statusCode === 200) {
      return response.data.catList;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching category list");
  }
};
// api to fetch category options
export const fetchOptions = async (listId) => {
  try {
    const response = await axiosClient.get(
      `category-list-options/getAllCategoryList/${listId}`
    );

    if (response.data.statusCode === 200) {
      return response.data.catList;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching list options");
  }
};
// api to post Order
export const postOrder = async (formData) => {
  try {
    const response = await axiosClient.post("order/CreateOrder", formData);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Posting my service");
  }
};
// api to fetch category options
export const getAllOrders = async (formID) => {
  try {
    const response = await axiosClient.put(`order/ListAllOrder`, formID);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching list options");
  }
};
// Api to Block calander
export const BlockCalander = async (providerID) => {
  try {
    const response = await axiosClient.get(
      `order/BlockCalanderDetail/${providerID}`
    );

    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching Events");
  }
};
// Api to Block calander on meeting
export const BlockCalanderMeetings = async (id) => {
  try {
    const response = await axiosClient.get(
      `order/BlockCalanderDetailMsg/${id}`
    );

    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching Meetings");
  }
};
// Api to reject order
export const statusUpdateOrder = async (reqData, orderId) => {
  try {
    const response = await axiosClient.put(
      `/order/StatusUpdateOrder/${orderId}`,
      reqData
    );

    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching Services");
  }
};
// api to post request
export const sendRequestQuote = async (formData) => {
  try {
    const response = await axiosClient.post("message/RequestQuote", formData);
    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Posting requesting quote");
  }
};
// api to Send message
export const sendMessage = async (formData) => {
  try {
    const response = await axiosClient.post("message/CreateMessage", formData);
    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Sending Message");
  }
};
// api to Send Offer
export const MakeAnOffer = async (formData) => {
  try {
    const response = await axiosClient.post("message/MakeOffer", formData);
    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Send An Offer");
  }
};
// api to negotiation
export const NegotiateOffer = async (negot) => {
  try {
    const response = await axiosClient.post(
      "message/MakeOfferNegotiation",
      negot
    );
    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Negotiate");
  }
};
// api to message status update
export const MessageStatusUpdate = async (formData, msgId) => {
  try {
    const response = await axiosClient.put(
      `message/StatusUpdateMessage/${msgId}`,
      formData
    );
    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Send An Offer");
  }
};
// api to fetch category options
export const getAllMessages = async (messageForm) => {
  try {
    const response = await axiosClient.put(
      `message/ListAllMessage`,
      messageForm
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching messages");
  }
};

// api to post review
export const sendReview = async (formData) => {
  try {
    const response = await axiosClient.post("review/CreateReview", formData);
    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error Posting Review");
  }
};
// api to get all reviews
export const getAllReviews = async (formID) => {
  try {
    const response = await axiosClient.put(`review/ListAllReview`, formID);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching list options");
  }
};
// api to get reviews by service id
export const ReviewsById = async (id) => {
  try {
    const response = await axiosClient.get(`review/ListReviewById/${id}`);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching list options");
  }
};
// api to fetch calendar events
export const fetchCalanderEvents = async (calendarId) => {
  try {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const response = await axiosClient.get(
      `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?key=${apiKey}`
    );
    return response.data.items;
  } catch (error) {
    console.error("Error fetching calendarEvents:", error);
  }
};
// api to add a deal
export const addDeal = async (formData) => {
  try {
    const response = await axiosClient.post("deal/addDeal", formData);
    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error adding new deal");
  }
};

// api to get a cashOut
export const getCashOutAdmin = async () => {
  try {
    const response = await axiosClient.get(`order/cashoutAdmin`);

    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching users");
  }
};

// api to add a paypal payment
export const addPaypalPament = async (formData) => {
  try {
    const response = await axiosClient.post("order/paypalPayment", formData);
    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error adding new payment");
  }
};

// api to get a paypal history
export const getPaypalHistory = async (Id) => {
  try {
    const response = await axiosClient.put(
      `order/paypalHistoryByReceiverId/${Id}`
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching payment Histrory");
  }
};

// api to get a paytoProvider's List history
export const getAdminPayToProiderList = async (Id) => {
  try {
    const response = await axiosClient.put(
      `order/AdminPayToProviderList/${Id}`
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching Provider list Histrory");
  }
};

export const downloadPaymentCSV = async (formData) => {
  try {
    const response = await axiosClient.post(
      "order/DownloadPaymentCsv",
      formData
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching payment CSV");
  }
};

export const viewPhonePayment = async (formData, id) => {
  try {
    const response = await axiosClient.put(
      `message/ViewPhonePayment/${id}`,
      formData
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching payment CSV");
  }
};

export const reportBusiness = async (formData) => {
  try {
    const response = await axiosClient.post(
      "services/ReportBusiness",
      formData
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching payment CSV");
  }
};

// api to get top 5 vendors
export const topFiveVendorDetails = async () => {
  try {
    const response = await axiosClient.get(`review/TopFiveVendorDetails`);

    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching users");
  }
};
// api to Add Favourite vendors
export const addFavourite = async (formData) => {
  try {
    const response = await axiosClient.post("review/AddFavourite", formData);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching payment CSV");
  }
};
//api to get User by email
export const getUserbyEmail = async (email) => {
  try {
    const response = await axiosClient.post(`auth/getUserbyEmail`, { email });

    if (response.data.statusCode === 200) {
      return response.data.userList;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching users");
  }
};
//api to notify provider
export const notifyProvider = async (formData) => {
  try {
    const response = await axiosClient.post(`setting/NotifyProvider`, formData);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching payment CSV");
  }
};
//api to List Favoyrite Vendor
export const listFavourite = async (id) => {
  try {
    const response = await axiosClient.get(`review/ListFavourite/${id}`);

    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error fetching vendors");
  }
};
//api to Missed Call SMS
export const missedCallSMS = async (formData, Id) => {
  try {
    const response = await axiosClient.put(
      `message/MissedCallSms/${Id}`,
      formData
    );
    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error to send Missed Call SMS");
  }
};
// api to update notify me settings
export const notifySelect = async (Id) => {
  try {
    const response = await axiosClient.put(`setting/NotifySelect/${Id}`);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error to get Notify Select");
  }
};
// Api to get Notify Data List
export const notifyDatalList = async (Id) => {
  try {
    const response = await axiosClient.put(`setting/NotifyDataList/${Id}`);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error to get Notify Data List");
  }
};

// Api to Upload CA ID
export const uploadCAID = async (Id, formData) => {
  try {
    const response = await axiosClient.put(
      `setting/CAIdUpload/${Id}`,
      formData
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error while uploading file");
  }
};

// Api to Upload CA ID Status Update
export const CAIdStatusUpdate = async (Id, formData) => {
  try {
    const response = await axiosClient.put(
      `setting/CAIdStatusUpdate/${Id}`,
      formData
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error while updating the status");
  }
};

// api to delete notify me settings
export const notifyDelete = async (Id, formData) => {
  try {
    const response = await axiosClient.put(
      `setting/NotifyDelete/${Id}`,
      formData
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error to get Notify Select");
  }
};

// api to Endorsemnt of service
export const serviceEndorsement = async (Id, formData) => {
  try {
    const response = await axiosClient.put(
      `services/Endorsement/${Id}`,
      formData
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error to get Service Endorsement ");
  }
};

// api to Endorsemnt of service
export const endorsementStatus = async (Id, formData) => {
  try {
    const response = await axiosClient.put(
      `services/EndorsementStatus/${Id}`,
      formData
    );
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error to get Service Endorsement Status");
  }
};

export const SaveBizz = async (formData) => {
  try {
    const response = await axiosClient.post(`services/SaveBizz`, formData);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error to post Service Bizz Value");
  }
};
export const saveBizzNeed = async (formData) => {
  try {
    const response = await axiosClient.post(`need/SaveBizz`, formData);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error to post Need Bizz Value");
  }
};

export const makeCall = async (formData) => {
  try {
    const response = await axiosClient.post(`message/makeCall`, formData);
    console.log(response, "Data");
    // Check for success based on the response structure
    if (response.data === "initiating call") {
      return response.data; // Return the data if successful
    } else {
      throw new Error(response.data.msg || "Unexpected error occurred");
    }
  } catch (error) {
    console.error(error); // Log the actual error for debugging
    throw new Error("Error to makeCall");
  }
};

export const sendMsgMail = async (formData) => {
  try {
    const response = await axiosClient.post(`message/sendMsgMail`, formData);
    console.log(response, "DataReponse");
    if (response.data === "Messages sent successfully!") {
      return response.data;
    } else {
      throw new Error(response.data.msg);
    }
  } catch (error) {
    throw new Error("Error to send Message and Mail");
  }
};
