import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import Sidebar from "../../SideBar";
import { FadeLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { BiPlusCircle } from "react-icons/bi";
import axiosClient from "../../../axios-client";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ItemsPending from "./ItemsPending";
import "./itemsForSale.css";
import ItemsOutOfStock from "./ItemsOutOfStock";
import ItemsInStock from "./ItemsInStock";

const MyItemsForSale = () => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin";
  const [activeTab, setActiveTab] = useState("InStock");
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const itemsPending = items?.filter((item) => item.item_status === 0);
  const itemsActive = items?.filter((item) => item.item_status === 1);
  // const ItemsUnSold = itemsActive?.filter((item) => item.quantity > 0);
  // const ItemsSold = itemsActive?.filter((item) => item.quantity === 0);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const getAllIfs = async () => {
    setIsLoading(true);
    if (isAdmin) {
      try {
        const response = await axiosClient.get(`item/getAllItem`);
        if (response.data.statusCode === 200) {
          setItems(response.data.itemList);
        } else {
          toast.error(`${response.data.message} ${response.data.statusCode}`);
        }
      } catch (error) {
        throw new Error("Error getting items");
      }
    } else {
      try {
        const response = await axiosClient.get(
          `item/getItemById/${user.UserID}`
        );
        if (response.data.statusCode === 200) {
          setItems(response.data.itemList);
        } else {
          toast.error(`${response.data.message} ${response.data.statusCode}`);
        }
      } catch (error) {
        throw new Error("Error getting items");
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getAllIfs();
  }, []);
  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row>
          <Col sm={4} lg={3}>
            <Sidebar />
          </Col>
          <Col sm={8} lg={9} className="requests-container position-relative ">
            <div>
              <div className="d-flex justify-content-end">
                <Button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    navigate("/create-item");
                  }}
                >
                  <BiPlusCircle /> Add New Item
                </Button>
              </div>
              <div
                className="d-flex justify-space-between row m-2 mt-3 wc_ib-tabs"
                style={{ marginLeft: "2px" }}
              >
                <h5
                  className={`col-sm-3 ${
                    activeTab === "InStock" ? "active" : ""
                  } mx-0`}
                  onClick={() => handleTabChange("InStock")}
                  style={{
                    textDecoration: activeTab !== "OutOfStock " && "none",
                    cursor: "pointer",
                    width: "25%",
                    fontSize: "14px",
                  }}
                >
                  Active Items
                </h5>

                <h5
                  className={`col-sm-3 ${
                    activeTab === "Pending" ? "active" : ""
                  } mx-0`}
                  onClick={() => handleTabChange("Pending")}
                  style={{
                    textDecoration: activeTab !== "InStock" && "none",
                    cursor: "pointer",
                    width: "25%",
                    fontSize: "14px",
                  }}
                >
                  Pending Items
                </h5>
                <h5
                  className={`col-sm-3 ${
                    activeTab === "selling" ? "active" : ""
                  } mx-0`}
                  onClick={() => handleTabChange("selling")}
                  style={{
                    cursor: "pointer",
                    width: "25%",
                    fontSize: "14px",
                  }}
                >
                  Selling Orders
                </h5>
                <h5
                  className={`col-sm-3 ${
                    activeTab === "purchaged" ? "active" : ""
                  } mx-0`}
                  onClick={() => handleTabChange("purchaged")}
                  style={{
                    cursor: "pointer",
                    width: "25%",
                    fontSize: "14px",
                  }}
                >
                  Purchaged Orders
                </h5>
              </div>
              {activeTab === "InStock" && (
                <ItemsInStock
                  ItemsUnSold={itemsActive}
                  fetchData={getAllIfs}
                  loading={isLoading}
                />
              )}

              {activeTab === "Pending" && (
                <ItemsPending
                  itemsPending={itemsPending}
                  fetchData={getAllIfs}
                  loading={isLoading}
                />
              )}
              {activeTab === "selling" && (
                <div></div>
                // <MySelling
                //   itemsActive={itemsActive}
                //   fetchData={getAllIfs}
                //   loading={isLoading}
                // />
              )}
              {activeTab === "purchaged" && (
                <div></div>
                // <MyPurchaged
                //   itemsActive={itemsActive}
                //   fetchData={getAllIfs}
                //   loading={isLoading}
                // />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default MyItemsForSale;
