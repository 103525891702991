import React from "react";
import { Button, Label } from "reactstrap";
import Switch from "@mui/material/Switch";

const MoreFilters = () => {
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <div>
      <Label>More Results</Label>
      <div className="d-flex justify-content-between">
        <Button>More Filters</Button>
        <Button>Clear Filters</Button>
        <div>
          Show Map : <Switch onChange={(event) => handleChange(event)} />
        </div>
      </div>
    </div>
  );
};

export default MoreFilters;
