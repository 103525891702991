import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
// import SearchByLocation from "./SearchByLocation";
import Helmet from "../../components/Helmet/Helmet";
import SearchByUsers from "./SearchByUsers";
import SearchByLocation from "./SearchByLocation";
import "./PeopleInMyCommunity.css";
import MoreFilters from "./MoreFilters";
import { fetchAllUsers } from "../../components/Header/Data";
import { useDispatch, useSelector } from "react-redux";
import { setAllUsers } from "../../redux/slices/PeopleInComSlice";
import UsersContainer from "./UsersContainer";

const PeopleInMyCom = () => {
  const dispatch = useDispatch();
  const { allUsers } = useSelector((state) => state.peopleInCom);
  const fetchUsers = async () => {
    const users = await fetchAllUsers();
    dispatch(setAllUsers(users));
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  return (
    <Helmet title="my-community">
      <div className="wc-people-community">
        <Row className="mb-2">
          <Col lg={4} md={4} xl={4} sm={12} xs={12}>
            <SearchByUsers />
          </Col>
          <Col lg={4} md={4} xl={4} sm={12} xs={12}>
            <SearchByLocation />
          </Col>
          <Col lg={4} md={4} xl={4} sm={12} xs={12}>
            <MoreFilters />
          </Col>
        </Row>
        <div>
          <UsersContainer />
        </div>
      </div>
    </Helmet>
  );
};

export default PeopleInMyCom;
