import { Alert, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import axiosClient from "../../axios-client";
import { toast } from "react-toastify";

const AddToCartModal = ({
  open,
  toggle,
  selectedItem,
  getAllIfs,
  myCartItems,
}) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [typeOfDelivery, setTypeOfDelivery] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");

  // Array of delivery types with their values and labels
  const deliveryOptionsMap = {
    1: { label: "Standard 3 Days Delivery", cost: "$5.00" },
    2: { label: "Same Day Delivery", cost: "$10.00" },
    3: { label: "Pick Up In Person", cost: "Free" },
  };

  // Parse delivery options from selectedItem
  const Options =
    selectedItem && selectedItem.delivery_option
      ? JSON.parse(selectedItem.delivery_option) // Assuming this is a stringified JSON array
      : [];

  const deliveryOptions = Options.map((value) => ({
    value,
    ...deliveryOptionsMap[value], // Get the corresponding delivery option from the map
  }));
  console.log(typeOfDelivery, "typeOfDelivery");
  const handleChange = (event) => {
    setTypeOfDelivery(event.target.value);
  };
  const toggleModal = () => {
    toggle();
    setTypeOfDelivery("");
    setAddress("");
    setMessage("");
  };
  const AddtoCart = async () => {
    const request = {
      userId: user.UserID,
      itemId: selectedItem.Id,
      Description: message,
      Quantity: 1,
      Price: selectedItem.price,
      cartStatus: 0,
    };
    try {
      const response = await axiosClient.post("item/AddToCart", request);
      if (response.status === 200) {
        toast.success(`${selectedItem.name} added to cart successfully`);
        toggleModal();
      }
    } catch (error) {
      toast.error("Failed to add item to cart");
    }

    getAllIfs();
    myCartItems();
  };
  console.log(selectedItem, "selectedItem");
  return (
    <div>
      <Modal isOpen={open} toggle={toggleModal}>
        <Form onSubmit={AddtoCart}>
          <ModalHeader toggle={toggleModal}>
            Add {selectedItem?.name} To Cart
          </ModalHeader>
          <ModalBody>
            <Box sx={{ minWidth: 70 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Delivery Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={typeOfDelivery}
                  label="Delivery Type"
                  onChange={handleChange}
                  required={true}
                  fullWidth
                >
                  {/* Dynamically render the delivery options */}
                  {deliveryOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                      <span className="text-primary fw-bold ps-1">
                        {option.cost}
                      </span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {typeOfDelivery === 1 || typeOfDelivery === 2 ? (
              <Box mt={2}>
                <TextField
                  fullWidth
                  label="Delivery Address"
                  variant="outlined"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required={typeOfDelivery === 1 || 2}
                />
              </Box>
            ) : typeOfDelivery === 3 ? (
              <Box mt={2}>
                <p className="text-danger ">
                  Your item will be available at the below address, Please
                  message the seller to ask availability schedule.
                </p>
              </Box>
            ) : null}
            <div className="mt-3">
              <h5>Send Seller a Message</h5>
              <p>
                If you want to ask about Pickup Instructions or if you have
                questions about the item
              </p>
              <TextField
                id="outlined-multiline-static"
                label="Message"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary">Add To Cart</Button>{" "}
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default AddToCartModal;
