import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { FaDollarSign } from "react-icons/fa";
import { useSelector } from "react-redux";
import axiosClient from "../../../axios-client";
import userIcon from "../../../assets/images/ext/user-profile.jpg"; // Assuming this is the placeholder image
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import "./itemCard.css"; // Optional, for additional styling if needed

export default function ItemsInCart() {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [profileItems, setProfileItems] = React.useState([]);
  const [sending, setSending] = React.useState(false);
  const navigate = useNavigate();
  const getMyCart = async () => {
    try {
      const response = await axiosClient.get(`item/getCartItem/${user.UserID}`);
      if (response.status === 200) {
        setProfileItems(response.data.cartList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getMyCart();
  }, []);

  const handleRemove = async (item) => {
    setSending(true);
    console.log(item, "item");
    const request = {
      itemId: item.item_id,
      cartStatus: 2,
    };
    try {
      const response = await axiosClient.put(
        `item/StatusUpdateCart/${user.UserID}`,
        request
      );
      if (response.data.statusCode === 200) {
        toast.success(`${item.name} Removed`);
        getMyCart();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setSending(false);
  };

  const handlePlaceOrder = async (item) => {
    navigate("/order/summary", {
      state: {
        itemOrder: item,
        itemCost: Number(item.price),
        item: true,
      },
    });
  };

  return (
    <div
      style={{
        overflowX: "auto",
        display: "flex",
        padding: "10px",
        whiteSpace: "nowrap",
      }}
    >
      {profileItems &&
        profileItems.map((item) => (
          <Card
            sx={{ maxWidth: 200, minWidth: 150, margin: "0 10px" }}
            key={item.id}
          >
            <CardMedia
              component="img"
              height="140"
              image={item.image && item.image !== "" ? item.image : userIcon} // Adjust to your item image URL field
              alt={item.name} // Adjust to your item title
            />
            <CardContent className="p-2">
              <Typography gutterBottom variant="body1" component="div" noWrap>
                {item.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", marginBottom: "10px" }}
              >
                ${item.price}
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "10px" }}>
                Address: {item.deliveryAddress || "N/A"}{" "}
                {/* Example Address Placeholder */}
              </Typography>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                {/* Remove Button */}
                <Button
                  onClick={() => handleRemove(item)}
                  className="btn btn-sm btn-danger"
                  disabled={sending}
                >
                  Remove
                </Button>
                <Button
                  onClick={() => handlePlaceOrder(item)}
                  className="btn btn-sm btn-success"
                  disabled={sending}
                >
                  Place Order
                </Button>
              </div>
            </CardContent>
          </Card>
        ))}
    </div>
  );
}
