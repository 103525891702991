import React, { useEffect, useState } from "react";
import LcaCards from "./LcaCards";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../../../redux/slices/LcaSlice";
import { Button } from "reactstrap";
import BwvCards from "./BwvCards";
import { fetchBWV, fetchLocalCommunityAds } from "../Data";

const LcaAndBwv = ({ scrollPopupVisible }) => {
  const dispatch = useDispatch();
  const {
    activeTab,
    finalProducts,
    Lca,
    bizzLca,
    bwv,
    finalBwv,
    selectedSubCategory,
  } = useSelector((state) => state.lcAds);

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;
  const [carouselData, setCarouselData] = useState({
    row1: [],
    row2: [],
    row3: [],
  });
  const currentBwv = useSelector((state) => state.lcAds.bwv); // Access latest bwv
  const shuffleArray = (array) => {
    let shuffled = array.slice();
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };
  const fetchData = async () => {
    const allAds = [...bizzLca, ...currentBwv]; // Merge with the latest bwv
    const row1 = [];
    const row2 = [];
    const row3 = [];
    allAds.forEach((data) => {
      if (data.bizzValue === 1) {
        row1.push(data);
      } else if (data.bizzValue === 2) {
        row2.push(data);
      } else if (data.bizzValue === 3) {
        row3.push(data);
      }
    });
    const shuffledRow1 = shuffleArray(row1);
    const shuffledRow2 = shuffleArray(row2);
    const shuffledRow3 = shuffleArray(row3);
    setCarouselData({
      row1: shuffledRow1,
      row2: shuffledRow2,
      row3: shuffledRow3,
    });
  };
  useEffect(() => {
    fetchData();
  }, [bizzLca, currentBwv]);
  // useEffect(() => {
  //   if (activeTab === "Customers") {
  //     setLoading(!(finalProducts?.length > 0));
  //   } else if (activeTab === "Vendor") {
  //     setLoading(!(finalBwv?.length > 0));
  //   }
  // }, [finalProducts, finalBwv, activeTab]);

  // Pagination calculations for Customers
  const totalPagesForCustomers = Math.ceil(
    finalProducts?.length / usersPerPage
  );
  const indexOfLastUserCustomer = currentPage * usersPerPage;
  const indexOfFirstUserCustomer = indexOfLastUserCustomer - usersPerPage;
  const currentUsers = finalProducts?.slice(
    indexOfFirstUserCustomer,
    indexOfLastUserCustomer
  );

  // Pagination calculations for Vendors
  const totalPagesForVendors = Math.ceil(finalBwv?.length / usersPerPage);
  const indexOfLastUserVendor = currentPage * usersPerPage;
  const indexOfFirstUserVendor = indexOfLastUserVendor - usersPerPage;
  const currentVendors = finalBwv?.slice(
    indexOfFirstUserVendor,
    indexOfLastUserVendor
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const handleNextPage = (totalPages) => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

  return (
    <>
      {selectedSubCategory &&
        selectedSubCategory.Name === "Upcoming Wedding/Event" && (
          <div
            className="col-lg-6 d-flex row mt-3 view-tabs w-100"
            style={{ marginLeft: "2px" }}
          >
            <h5
              className={`col-sm-4 ${
                activeTab === "Customers" ? "active " : ""
              } mx-0`}
              onClick={() => {
                dispatch(setActiveTab("Customers"));
                setCurrentPage(1); // Reset to first page when switching tabs
              }}
              style={{
                textDecoration:
                  activeTab === "Customers" ? "underline" : "none",
                cursor: "pointer",
                width: "50%",
              }}
            >
              View Community Ads
            </h5>
            <h5
              className={`col-sm-4 ${
                activeTab === "Vendor" ? "active" : ""
              } mx-0`}
              onClick={() => {
                dispatch(setActiveTab("Vendor"));
                setCurrentPage(1); // Reset to first page when switching tabs
              }}
              style={{
                textDecoration: activeTab === "Vendor" ? "underline" : "none",
                cursor: "pointer",
                width: "50%",
              }}
            >
              View Vendors
            </h5>
          </div>
        )}
      <div>
        {" "}
        {activeTab === "Customers" ? (
          <LcaCards
            allProducts={finalProducts}
            scrollPopupVisible={scrollPopupVisible}
            carouselData={carouselData}
          />
        ) : activeTab === "Vendor" ? (
          currentVendors && currentVendors.length > 0 ? (
            <BwvCards
              allProducts={currentVendors}
              carouselData={carouselData}
            />
          ) : (
            <div
              className="text-center py-5 text-primary"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "50px 0px",
              }}
            >
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "10px 0px",
                  fontSize: "20px",
                }}
                className="text-center py-5"
              >
                No cards to display
              </p>
            </div>
          )
        ) : null}
      </div>

      {(activeTab === "Customers" || activeTab === "Vendor") && (
        <div className="pagination pb-3 d-flex justify-content-center">
          <Button
            className="me-2"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span className="align-self-center">
            Page {currentPage} of{" "}
            {activeTab === "Customers"
              ? totalPagesForCustomers
              : totalPagesForVendors}
          </span>
          <Button
            className="ms-2"
            onClick={() =>
              handleNextPage(
                activeTab === "Customers"
                  ? totalPagesForCustomers
                  : totalPagesForVendors
              )
            }
            disabled={
              currentPage ===
              (activeTab === "Customers"
                ? totalPagesForCustomers
                : totalPagesForVendors)
            }
          >
            Next
          </Button>
        </div>
      )}
    </>
  );
};

export default LcaAndBwv;
