import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap";

const UsersList = () => {
  const navigate = useNavigate();
  const { allUsers } = useSelector((state) => state.peopleInCom);
  return (
    <div>
      <Row>
        {allUsers.map((user, index) => (
          <Col xs="12" sm="6" md="4" key={index} className="mb-3">
            <Card
              className="people-user-card"
              style={{
                borderRadius: "10px",
                overflow: "hidden",
              }}
              onClick={() => {
                navigate(`/people-profile/${user.Id}`);
                // window.location.href = `/people-profile/${user.Id}`;
              }}
            >
              <CardBody>
                <div className="d-flex  align-items-center">
                  <img
                    className="rounded-circle"
                    src={
                      user.Photo
                        ? user.Photo
                        : "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                    }
                    alt="User Profile"
                    style={{
                      width: "60px",
                      height: "60px",
                      objectFit: "cover",
                      marginBottom: "10px",
                    }}
                  />
                  <CardTitle tag="h5" style={{ fontWeight: "bold" }}>
                    {user.FirstName} {user.LastName}
                  </CardTitle>
                </div>

                <CardText style={{ fontSize: "14px", color: "#555" }}>
                  {user.description}
                </CardText>
                <CardText style={{ fontSize: "12px", color: "#888" }}>
                  <small>
                    {user.lastActive} - {user.location}
                  </small>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default UsersList;
