import React, { useState, useEffect } from "react";
import Sidebar from "../../SideBar";
import { Row, Col } from "reactstrap";
import "../DashboardPages.css";
import { getAllOrders } from "../../../components/Header/Data";
import { useSelector } from "react-redux";
import AsProvider from "./AsProvider";
import AsClint from "./AsClint";
import ItemsInCart from "./ItemsInCart";
const Orders = () => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [selectedType, setSelectedType] = useState("As provider");
  const [adminOrders, setAdminOrders] = useState([]);
  const [providerOrders, setProviderOrders] = useState([]);
  const [clintOrders, setClintOrders] = useState([]);
  const fetchData = async () => {
    try {
      if (user.RoleId === 1) {
        const formId = {
          roleId: user.RoleId,
        };
        const ordersData = await getAllOrders(formId);
        setAdminOrders(ordersData);
      } else {
        const formIdProvider = {
          ownerId: user.UserID,
        };
        const providersData = await getAllOrders(formIdProvider);
        setProviderOrders(providersData);
        const formId = {
          createdBy: user.UserID,
        };
        const clintsData = await getAllOrders(formId);
        setClintOrders(clintsData);
      }
    } catch (error) {
      console.error("Error fetching orders:", error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleTabChange = (tab) => {
    setSelectedType(tab);
  };
  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row>
          <Col sm={4} lg={3}>
            <Sidebar />
          </Col>

          {adminOrders.length > 0 ? (
            <Col sm={8} lg={9}>
              <Row className="orders-container inter-font">
                <AsProvider orders={adminOrders} />
              </Row>
            </Col>
          ) : (
            <Col sm={8} lg={9}>
              <Row className="orders-container inter-font mt-5">
                <div
                  className="d-flex justify-space-between row m-2 mt-3 wc_ib-tabs"
                  style={{ marginLeft: "2px" }}
                >
                  <h5
                    className={`col-sm-4 ${
                      selectedType === "As provider" ? "active" : ""
                    } mx-0 inter-font`}
                    onClick={() => handleTabChange("As provider")}
                    style={{
                      textDecoration: selectedType === "As provider " && "none",
                      cursor: "pointer",
                      width: "33%",
                      fontSize: "14px",
                    }}
                  >
                    As Provider
                  </h5>
                  <h5
                    className={`col-sm-4 ${
                      selectedType === "As client" ? "active" : ""
                    } mx-0 inter-font`}
                    onClick={() => handleTabChange("As client")}
                    style={{
                      textDecoration: selectedType === "As client" && "none",
                      cursor: "pointer",
                      width: "33%",
                      fontSize: "14px",
                    }}
                  >
                    As Client
                  </h5>
                  <h5
                    className={`col-sm-4 ${
                      selectedType === "items-in-cart" ? "active" : ""
                    } mx-0 inter-font`}
                    onClick={() => handleTabChange("items-in-cart")}
                    style={{
                      textDecoration:
                        selectedType === "items-in-cart" && "none",
                      cursor: "pointer",
                      width: "33%",
                      fontSize: "14px",
                    }}
                  >
                    Items In Cart
                  </h5>
                </div>
                {/* <Row className="mt-3"> */}
                {selectedType === "As provider" && (
                  <AsProvider orders={providerOrders} />
                )}
                {selectedType === "As client" && (
                  <AsClint orders={clintOrders} />
                )}
                {selectedType === "items-in-cart" && <ItemsInCart />}
                {/* </Row> */}
              </Row>
            </Col>
          )}
        </Row>
      </div>
    </section>
  );
};

export default Orders;
