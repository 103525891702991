import React, { useEffect, useState } from "react";
import { Input, Label, ListGroup, ListGroupItem } from "reactstrap";

const SearchByLocation = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await fetch(
          "https://countriesnow.space/api/v0.1/countries/population/cities"
        );
        const data = await response.json();
        const allCities = data.data.map((city) => city.city);
        setCities(allCities);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    fetchCities();
  }, []);

  useEffect(() => {
    const results = cities.filter((city) =>
      city.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm, cities]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCitySelect = (city) => {
    setSearchTerm(city);
    setSearchResults([]);
    setSelectedCity(city);
  };
  const noResultsFound = searchTerm && searchResults.length === 0;
  return (
    <div>
      <Label>Search By Location</Label>
      <Input
        type="text"
        value={searchTerm}
        onChange={handleInputChange}
        placeholder="Search cities..."
      />
      {searchTerm && searchResults.length > 0 && (
        <ListGroup>
          {searchResults.map((city, index) => (
            <ListGroupItem
              key={index}
              onClick={() => handleCitySelect(city)}
              style={{ cursor: "pointer" }}
            >
              {city}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}

      {!selectedCity && noResultsFound && <p>No Cities Founds</p>}
    </div>
  );
};

export default SearchByLocation;
