import React, { useEffect, useState } from "react";
import { Input, Label, ListGroup, ListGroupItem } from "reactstrap";
import { fetchAllUsers } from "../../components/Header/Data";
import { useSelector } from "react-redux";

const SearchByUsers = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [userSelected, setUserSelected] = useState(null);

  // Fetches all users and sets the initial state
  const { allUsers } = useSelector((state) => state.peopleInCom);
  const handleInputChange = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    // Filter users based on search term matching Firstname or Lastname
    const filteredResults = allUsers.filter(
      (user) =>
        user.FirstName?.toLowerCase().includes(term) ||
        user.LastName?.toLowerCase().includes(term)
    );
    setSearchResults(filteredResults);
  };

  const handleSelect = (user) => {
    const name = `${user.FirstName} ${user.LastName}`; // Concatenate FirstName and LastName
    setSearchTerm(name); // Set the search term to the selected user's name
    setSearchResults([]); // Clear search results after selection
    setUserSelected(user);
  };

  const handleClearInput = () => {
    setSearchTerm(""); // Clear the input field
    setSearchResults([]); // Clear search results
    setUserSelected(null); // Clear the selected user
  };

  // Check if no results match the current search term and it's not a selected user
  const noResultsFound = searchTerm && searchResults.length === 0;

  return (
    <div>
      <Label>Search For People</Label>
      <div className="input-container">
        <Input
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          placeholder="Search by Firstname, Lastname ..."
          className="search-input"
        />
        {/* Show clear button only if there is text in the input field */}
        {searchTerm && (
          <span className="clear-button" onClick={handleClearInput}>
            &#x2715; {/* Unicode for the X symbol */}
          </span>
        )}
      </div>
      {/* Show results only if there is a search term */}
      {searchTerm && searchResults.length > 0 && (
        <ListGroup>
          {searchResults.map((user, index) => (
            <ListGroupItem
              key={index}
              style={{ cursor: "pointer" }}
              onClick={() => handleSelect(user)} // Call handleSelect on click
            >
              <small>
                {user.FirstName} {user.LastName} <br />
              </small>
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
      {/* Only show the message if there are no results found */}
      {!userSelected && noResultsFound && <p>No users found</p>}
    </div>
  );
};

export default SearchByUsers;
