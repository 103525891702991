import React from "react";
import userIcon from "../../assets/images/ext/user-profile.jpg";

const PeopleProfileDetails = ({ userDetails }) => {
  return (
    <div>
      <div className="people-profile-img">
        <img
          className="w-75"
          src={
            userDetails.Photo && userDetails.Photo !== ""
              ? userDetails.Photo
              : userIcon
          }
        />
      </div>
      <h6 className="text-center text-nowrap">
        {userDetails.FirstName} {userDetails.LastName}
      </h6>
      <p className="text-center text-nowrap">{userDetails.Location}</p>
    </div>
  );
};

export default PeopleProfileDetails;
