import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import "./peopleProfile.css";
import { useParams } from "react-router-dom";
import { fetchUserDetails } from "../../components/Header/Data";
import PeopleProfileItems from "./PeopleProfileItems";
import PeopleProfileDetails from "./PeopleProfileDetails";

const PeopleProfile = () => {
  const { Id: userId } = useParams();
  const [userDetails, setUserDetails] = React.useState({});

  const getDetails = async () => {
    const userData = await fetchUserDetails(userId);
    setUserDetails(userData);
  };

  useEffect(() => {
    getDetails();
  }, [userId]);

  return (
    <div className="people-profile-container">
      <Row className="">
        <Col lg={4} xl={4} md={4} sm={12} xs={12}>
          <PeopleProfileDetails userDetails={userDetails} />
        </Col>
        {/* Divider between PeopleProfileDetails and PeopleProfileItems */}
        <Col lg={8} xl={8} md={8} sm={12} xs={12}>
          <PeopleProfileItems userDetails={userDetails} userId={userId} />
        </Col>
      </Row>
    </div>
  );
};

export default PeopleProfile;
